import { template as template_738836005e014bcf833e79430c2089ec } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_738836005e014bcf833e79430c2089ec(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
